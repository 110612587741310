import { template as template_ceeddafd277f42808bc613b49485090f } from "@ember/template-compiler";
const FKText = template_ceeddafd277f42808bc613b49485090f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
