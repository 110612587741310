import { template as template_de923f2aa23c4fa6bfce6c567dce8f14 } from "@ember/template-compiler";
const FKLabel = template_de923f2aa23c4fa6bfce6c567dce8f14(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
