import { template as template_c38ab9a06e034fd19ddb9e60d7602346 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_c38ab9a06e034fd19ddb9e60d7602346(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
