import { template as template_7453201348e94557a686f4aa2f640479 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_7453201348e94557a686f4aa2f640479(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
